import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useState } from 'react';
import { AccessTokenHandler } from '@typedream/data';
import getSpacePageID from './getSpacePageID';
import { USER_STORAGE_KEY } from '../constants';
import { IUser } from '../types';
import { ONBOARDING_STEPS_ORDER } from './onboardingSteps';
import { useRedirectToOnboarding } from './useRedirectToOnboarding';

export default function getLoggedInUser(): IUser | undefined {
  const userStr = localStorage?.getItem(USER_STORAGE_KEY);
  if (userStr) {
    const user: IUser = JSON.parse(userStr);
    return user;
  }
  return undefined;
}

export function useLoggedInUser(): {
  user: IUser | undefined,
  isLoggedIn: boolean,
  loading: boolean,
  redirectTo: string | undefined,
} {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setloading] = useState(true);
  const [user, setuser] = useState<IUser | undefined>(undefined);
  const [redirectTo, setredirectTo] = useState<string | undefined>(undefined);
  const redirectToOnboarding = useRedirectToOnboarding();

  const getRedirectTo = useCallback((_user: IUser) => {
    const {
      noSpaces, noPages, defaultSpaceID, defaultSpaceSlug,
    } = getSpacePageID(false);

    if (!_user?.metadata?.hear_from) {
      return '/onboarding/hearfrom';
    }
    if (noSpaces) {
      return `${ONBOARDING_STEPS_ORDER[0].link}`;
    }
    if (!defaultSpaceID) {
      return '/sites';
    }
    if (noPages) {
      return redirectToOnboarding(defaultSpaceID, defaultSpaceSlug!);
    }
    return `/s/${defaultSpaceSlug}/${defaultSpaceID}`;
  }, []);

  useEffect(() => {
    // check if user is logged in
    const accessToken = AccessTokenHandler.getAccessToken();
    setIsLoggedIn(!!(accessToken && accessToken.token.length > 0));

    const loggedInUser = getLoggedInUser();
    setuser(loggedInUser);
    try {
      mixpanel.identify(loggedInUser?.id);
    } catch (e) {
      // do nothing if can't call mixpanel.identify
    }

    if (loggedInUser) {
      const redirect = getRedirectTo(loggedInUser);
      setredirectTo(redirect);
    }
    setloading(false);
  }, [getRedirectTo]);

  return {
    user,
    isLoggedIn,
    loading,
    redirectTo,
  };
}
