import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Logo } from '../components/Logo';
import { SignupOrLogin } from '../components/SignupOrLogin';
import { LOGO_NAME } from '../constants';
import styles from '../styles/Home.module.css';
import { useLoggedInUser } from '../utils/useLoggedInUser';

function Home() {
  const router = useRouter();
  const { isLoggedIn, redirectTo } = useLoggedInUser();
  useEffect(() => {
    if (isLoggedIn && redirectTo) {
      router.push(redirectTo);
    }
  }, [isLoggedIn, redirectTo, router]);
  return (
    <div className={classNames(styles.page, styles.centered)}>
      <div className={styles.centeredContainer}>
        <div className={styles.logo}>
          <Logo height={50} />
        </div>
        <div className="title is-size-3 is-size-4-mobile has-text-weight-bold mb-4 has-text-centered">
          Welcome to
          {' '}
          {LOGO_NAME}
        </div>
        <div className="is-size-5 is-subtitle has-text-centered">
          Don&apos;t have an account?
          {' '}
          <span
            className="with-accent-text-color with-cursor-pointer"
            role="none"
            onClick={() => {
              router.push('/signup');
            }}
          >
            <u>Create Account</u>

          </span>
        </div>

        <SignupOrLogin login />

      </div>
    </div>
  );
}

export default Home;
